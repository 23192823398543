import React from 'react';

const Person = props => {
    const toggleLoadingAnimation = () => {
        if (props.isLoading) {
            return "loading";
        } else {
            return "not-loading";
        }
    }

    return (
        <svg id="person" viewBox="0 0 800 360" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
            <g id="Desk" transform="matrix(1.00108,0,0,0.889785,-1.29301,-174.939)">
                <path className="desk-item" d="M800.427,601.198L708.577,497.859L92.796,497.859L0.945,601.198L0.945,601.2L800.427,601.2L800.427,601.198Z" style={{fill: "rgb(235,235,235)"}}/>
            </g>
            <g transform="matrix(1,0,0,1,-9.41937,-241.318)">
                <path id="chair" className="chair-item" d="M513.824,365.397C513.824,359.536 511.496,353.914 507.351,349.77C503.207,345.625 497.585,343.297 491.724,343.297C451.446,343.297 366.539,343.297 326.26,343.297C320.399,343.297 314.778,345.625 310.633,349.77C306.489,353.914 304.16,359.536 304.16,365.397C304.16,410.593 304.16,509.367 304.16,509.367L513.824,509.367L513.824,365.397Z" />
            </g>
            <g id="Face" transform="matrix(1,0,0,1,-6.94096,-238.485)">
                <g transform="matrix(1,0,0,1.36164,6.30859,-126.967)">
                    <path className="face-item" d="M423.82,359.949L377.098,359.949L388.778,341.133L412.14,341.133L423.82,359.949Z" style={{fill:"rgb(235,235,235)", stroke:"rgb(235,235,235)", strokeWidth:"2px"}}/>
                </g>
                <g transform="matrix(1.25406,0,0,1.2687,-106.267,-92.0246)">
                    <ellipse className="face-item" cx="409.1" cy="314.426" rx="26.83" ry="34.25" style={{fill:"rgb(235,235,235)"}}/>
                </g>
                <g id="Glasses" transform="matrix(1,0,0,1,6.64566,-0.0115932)">
                    <g transform="matrix(1,0,0,1,30.9023,155.791)">
                        <path d="M357.434,145.258C365.043,145.258 372.827,145.66 380.762,145.66" style={{fill:"none", stroke:"black", strokeWidth:"2.5px"}}/>
                    </g>
                    <g transform="matrix(-1,1.22465e-16,-1.22465e-16,-1,607.858,475.822)">
                        <path d="M229.233,168.827C229.46,170.22 229.059,171.642 228.139,172.711C227.218,173.78 225.872,174.388 224.461,174.37C221.943,174.339 218.987,174.302 216.464,174.271C215.046,174.253 213.71,173.606 212.817,172.506C211.923,171.405 211.565,169.964 211.839,168.573C212.322,166.125 212.875,163.318 213.309,161.113C213.75,158.875 215.713,157.262 217.994,157.262C219.661,157.262 221.605,157.262 223.287,157.262C225.627,157.262 227.622,158.958 227.999,161.267C228.366,163.517 228.83,166.359 229.233,168.827Z" style={{fill:"none", stroke:"black", strokeWidth:"2.5px"}}/>
                    </g>
                    <g transform="matrix(-1,1.22465e-16,-1.22465e-16,-1,633.186,475.419)">
                        <path d="M229.233,168.827C229.46,170.22 229.059,171.642 228.139,172.711C227.218,173.78 225.872,174.388 224.461,174.37C221.943,174.339 218.987,174.302 216.464,174.271C215.046,174.253 213.71,173.606 212.817,172.506C211.923,171.405 211.565,169.964 211.839,168.573C212.322,166.125 212.875,163.318 213.309,161.113C213.75,158.875 215.713,157.262 217.994,157.262C219.661,157.262 221.605,157.262 223.287,157.262C225.627,157.262 227.622,158.958 227.999,161.267C228.366,163.517 228.83,166.359 229.233,168.827Z" style={{fill:"none", stroke:"black", strokeWidth:"2.5px"}}/>
                    </g>
                    <path d="M420.73,301.265C425.772,301.265 428.725,297.297 433.129,297.297" style={{fill:"none", stroke:"black", strokeWidth:"2.5px"}}/>
                    <g transform="matrix(-1,0,0,1,799.736,0.718875)">
                        <path d="M420.73,301.265C425.772,301.265 428.725,297.297 433.129,297.297" style={{fill:"none", stroke:"black", strokeWidth:"2.5px"}}/>
                    </g>
                </g>
                <g id="Hair" transform="matrix(0.463945,0,0,0.442664,111.555,200.145)">
                    <path d="M575.433,154.235L561.683,148.731C561.683,148.731 590.432,117.08 630.431,119.833L619.181,107.447C619.181,107.447 646.68,96.438 671.679,125.337C684.82,140.528 700.025,158.385 709.504,178.5L724.228,178.5L718.083,192.032L739.592,205.564L717.515,203.133C718.83,210.543 719.032,218.108 718.114,225.578C717.167,233.047 713.352,239.863 707.48,244.576C701.607,249.288 690.428,209.28 690.428,203.776L690.428,217.537C690.428,217.537 676.678,205.152 676.678,196.895L669.179,206.528L665.429,191.391L619.181,206.528L626.681,194.143L597.932,198.271L609.182,183.134C609.182,183.134 576.683,201.023 575.433,216.161C574.183,231.298 564.671,245.642 564.671,245.642C564.671,245.642 539.184,174.877 575.433,154.235Z" style={{fill:"rgb(47,46,65)", fillRule:"nonzero"}}/>
                </g>
            </g>
            <g id="Shirt" transform="matrix(1,0,0,1,-0.427181,-241.2)">
                <g transform="matrix(1,0,0,1,0.873939,-8.24609)">
                    <path className="shirt-item" d="M374.264,365.554L311.549,387.09C311.549,387.09 298.986,395.162 295.897,400.453C292.807,405.744 293.335,414.226 293.01,418.836C292.791,421.935 292.126,426.504 291.572,429.561C289.262,442.325 282.186,482.068 279.147,495.418C278.166,499.722 274.943,504.332 275.705,508.207C276.468,512.082 279.912,516.479 283.721,518.668C291.388,523.075 320.818,535.972 321.709,534.648C322.6,533.324 374.416,533.98 374.416,533.98L374.264,365.554ZM328.906,430.785L315.625,495.711L325.359,499.32L328.906,430.785Z" />
                </g>
                <g transform="matrix(-1,0,0,1,799.634,-8.24609)">
                    <path className="shirt-item" d="M374.264,365.554L311.549,387.09C311.549,387.09 298.986,395.162 295.897,400.453C292.807,405.744 293.335,414.226 293.01,418.836C292.791,421.935 292.126,426.504 291.572,429.561C289.262,442.325 282.186,482.068 279.147,495.418C278.166,499.722 274.943,504.332 275.705,508.207C276.468,512.082 279.912,516.479 283.721,518.668C291.388,523.075 320.818,535.972 321.709,534.648C322.6,533.324 374.416,533.98 374.416,533.98L374.264,365.554ZM328.906,430.785L315.625,495.711L325.359,499.32L328.906,430.785Z" />
                </g>
                <path className="shirt-item" d="M422.281,357.308L425.218,357.308L425.218,526.497L375.29,526.497L375.29,357.308L378.226,357.308C384.013,360.485 391.754,362.428 400.254,362.428C408.754,362.428 416.494,360.485 422.281,357.308Z" />
            </g>
            <g id="Laptop" transform="matrix(1,0,0,1,9.69356,-249.854)">
                <g transform="matrix(0.884301,0,0,0.802219,29.8248,115.452)">
                    <path className="laptop-item" d="M500.261,445.157C500.261,442.403 498.236,440.171 495.738,440.171C470.185,440.171 344.141,440.171 318.588,440.171C316.09,440.171 314.065,442.403 314.065,445.157C314.065,463.697 314.065,528.54 314.065,547.08C314.065,549.833 316.09,552.066 318.588,552.066C344.141,552.066 470.185,552.066 495.738,552.066C498.236,552.066 500.261,549.833 500.261,547.08C500.261,528.54 500.261,463.697 500.261,445.157Z" />
                </g>
                <g transform="matrix(1.13127,0,0,1.10756,-64.2014,-60.0513)">
                    <path className="laptop-item" d="M478.487,551.474C478.487,547.983 475.717,545.154 472.3,545.154L330.48,545.154C327.062,545.154 324.292,547.983 324.292,551.474L324.292,552.009C324.292,555.5 327.062,558.33 330.48,558.33L472.3,558.33C475.717,558.33 478.487,555.5 478.487,552.009L478.487,551.474Z"/>
                </g>
                <g transform="matrix(0.725604,0,0,0.725604,130.898,336.39)">
                    <circle id="loading-container" cx="356.918" cy="244.013" r="22" />
                    <circle className={toggleLoadingAnimation()} cx="356.918" cy="244.013"/>
                </g>
            </g>
            <g id="Mug" transform="matrix(1,0,0,1.38534,-4.86077,-471.177)">
                <g transform="matrix(1,0,0,1,-55.6016,320.715)">
                    <path className="mug-item" d="M615.453,196.863L615.453,228.582L606.971,228.582C606.234,228.582 605.556,228.29 605.207,227.821C600.587,221.301 598.938,210.936 598.994,198.302C598.998,197.507 599.892,196.864 600.994,196.864C605.369,196.863 615.453,196.863 615.453,196.863Z"/>
                    <path className="mug-item" d="M615.453,196.863L615.453,228.582L606.971,228.582C606.234,228.582 605.556,228.29 605.207,227.821C600.587,221.301 598.938,210.936 598.994,198.302C598.998,197.507 599.892,196.864 600.994,196.864C605.369,196.863 615.453,196.863 615.453,196.863ZM607.278,226.777L612.953,226.777L612.953,198.668L601.494,198.668C601.47,210.658 602.957,220.525 607.278,226.777Z"/>
                </g>
                <g transform="matrix(-1,0,0,1,1175.31,320.715)">
                    <path className="mug-item" d="M615.453,196.863L615.453,228.582L606.971,228.582C606.234,228.582 605.556,228.29 605.207,227.821C600.587,221.301 598.938,210.936 598.994,198.302C598.998,197.507 599.892,196.864 600.994,196.864C605.369,196.863 615.453,196.863 615.453,196.863Z"/>
                    <path className="mug-item" d="M615.453,196.863L615.453,228.582L606.971,228.582C606.234,228.582 605.556,228.29 605.207,227.821C600.587,221.301 598.938,210.936 598.994,198.302C598.998,197.507 599.892,196.864 600.994,196.864C605.369,196.863 615.453,196.863 615.453,196.863ZM601.494,198.668L612.953,198.668L612.953,226.777L607.279,226.777C602.952,220.493 601.47,210.638 601.494,198.668Z" />
                </g>
                <g transform="matrix(1,0,0,1,0,-4)">
                    <path className="mug-item" d="M576.322,525.457C580.538,525.457 583.961,529.634 583.961,534.779C583.961,539.924 580.538,544.101 576.322,544.101C572.106,544.101 568.684,539.924 568.684,534.779C568.684,529.634 572.106,525.457 576.322,525.457ZM576.322,528.705C577.024,528.705 577.389,529.281 577.849,529.842C578.883,531.104 579.461,532.856 579.461,534.779C579.461,536.702 578.883,538.455 577.849,539.716C577.389,540.277 577.024,540.853 576.322,540.853C575.621,540.853 575.255,540.277 574.795,539.716C573.762,538.455 573.184,536.702 573.184,534.779C573.184,532.856 573.762,531.104 574.795,529.842C575.255,529.281 575.621,528.705 576.322,528.705Z" />
                </g>
            </g>
        </svg>
    )
}

export default Person;