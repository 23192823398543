import React, { Component } from 'react';

class Footer extends Component {
    render() {
         return (
            <footer>
                Made by <span>Christian Cain &#169;</span>
            </footer>
         )
    }
}

export default Footer;